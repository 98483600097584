.availableMatches {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 40px 24px;
  position: relative;

  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      display: inline-block;
      margin: 4px 2px;
      background-color: #444;
      font-size: 14px;
      padding-left: 32px;
      padding-right: 32px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: white;
      text-decoration: none;
      cursor: pointer;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .button:hover {
      transition-duration: 0.4s;
      -moz-transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s;
      -o-transition-duration: 0.4s;
      background-color: white;
      color: black;
    }

    .search-container {
      #search-wrap {
        position: absolute;
        z-index: 2;
        top: 40px;
        right: 24px;
        background: rgb(11, 4, 26);
        border: 1px solid #ffffff;
        box-shadow: 0px 0px 5px #ffffff;
        border-radius: 60px;

        form {
          position: relative;

          img {
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }
      }

      #search-text {
        width: 42px;
        height: 42px;
        padding: 4px;
        // border-radius: 60px;
        border: none;
        background: url("../../images/icons/Icon_Search.svg") no-repeat 12px
          center;
        // background: url("../../images/icons/Icon_Search.svg") no-repeat center;
        background-size: 25px 25px;
        transition: all 0.5s;
        outline: none;
        color: rgba(255, 255, 255, 0);
        cursor: pointer;
      }

      #search-text:focus {
        width: 260px;
        padding: 4px 0 4px 60px;
        box-shadow: 0 2px rgba(6, 0, 1, 0.26);
        color: #fff;
      }
    }
  }

  .noAvailableMatches {
    margin-top: 80px;
    text-align: center;

    p {
      margin: 15px 0 20px;
    }

    .soloMatch {
      background-color: transparent;
      border: none;
      font-family: "Exo 2", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      text-decoration: underline;
      color: #fff;
      margin: auto;
      margin-top: 24px;
    }
  }

  .availableMatchesCards {
    margin-top: 80px;
    text-align: center;

    h2 {
      margin-bottom: 24px;
    }

    .matchCardContent {
      .matchCard {
        background: rgba(85, 77, 102, 0.25);
        border-radius: 5px;
        margin-bottom: 40px;
        &:nth-child(1) {
          margin-top: 50px;
        }

        &:nth-child(even) {
          .header {
            .cardNumber {
              background: url("../../images/blue.svg");
              height: 64px;
              width: 59px;
              background-repeat: no-repeat;
              background-position-x: center;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .header {
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 28px 30px;
          position: relative;

          .cardNumber {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.75px;
            color: #fff;
            position: relative;
            background: url("../../images/red.svg");
            height: 64px;
            width: 59px;
            background-repeat: no-repeat;
            background-position-x: center;
            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
              font-size: 22px;
            }

            // .hexagon {
            //   position: relative;
            //   display: block;
            //   overflow: hidden;
            //   width: 60px;
            //   height: 45px;
            //   margin: 0 auto;
            //   transform: rotate(180deg);
            //   position: absolute;
            //   top: -7px;
            //   left: -23px;
            // }

            // .hex-wrapper {
            //   position: absolute;
            //   display: block;
            //   overflow: hidden;
            //   width: 100%;
            //   height: 100%;
            //   transform: rotate(60deg);
            // }

            // .hex-border {
            //   position: absolute;
            //   display: block;
            //   overflow: hidden;
            //   width: 100%;
            //   height: 100%;
            //   box-shadow: 0px 0px 4px 3px #cf004b inset;
            //   transform: rotate(60deg);
            // }

            // .hex-border:before {
            //   content: "\0020";
            //   position: absolute;
            //   display: block;
            //   width: 100%;
            //   height: 100%;
            //   box-shadow: 0px 0px 4px 3px #cf004b inset;
            //   transform: rotate(60deg);
            // }

            // .hex-border:after {
            //   content: "\0020";
            //   position: absolute;
            //   display: block;
            //   width: 100%;
            //   height: 100%;
            //   box-shadow: 0px 0px 4px 3px #cf004b inset;
            //   transform: rotate(120deg);
            // }
          }

          .numberOfTeams {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 5px;

            // &::before {
            //   content: url("../../images/red.svg");
            //   position: absolute;
            //   left: 20px;
            //   top: 12px;
            // }

            h6 {
              color: #fff;
              text-shadow: 0px 0px 17px #ffffff;
            }

            span {
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 29px;
              letter-spacing: -0.75px;
              text-shadow: 0px 0px 17px #ffffff;
              color: #fff;
            }
          }
        }

        .content {
          padding: 24px 30px;
          text-align: left;
          border-top: 1px solid #48386b;
          border-bottom: 1px solid #48386b;

          h6 {
            color: #fff;
            text-shadow: 0px 0px 17px #ffffff;
            margin-bottom: 24px;
          }

          .roomFranchize,
          .location,
          .roomName {
            span {
              font-family: "Exo 2", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.28125px;
              color: #a6a6a6;
            }

            h5 {
              font-family: "Exo 2", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.75px;
              text-shadow: 0px 0px 17px #dcd7ff;
              color: #fff;
              margin-top: 8px;
            }
          }

          .location {
            margin: 24px 0;
          }
        }

        .seeMore {
          padding: 20px 30px;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            text-transform: uppercase;
            line-height: 29px;
            letter-spacing: -0.75px;
            color: #fff;
            text-shadow: 0px 0px 4px #ffffff;
          }
        }
      }
    }
  }
}
