.tokenError {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 24px;
  height: 100vh;

  h2 {
    margin-bottom: 10px;
  }
}
