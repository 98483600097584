.joinMatch {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 40px 24px;

  .joinMatchBtn {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .goBack {
    margin: 50px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;

    img {
      transform: rotateZ(90deg);
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .goBackDownPage {
    margin: 24px 0 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;

    img {
      transform: rotateZ(90deg);
    }
  }
}
