.matchLobbyScreen {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  //   padding: 20px 24px;
  position: relative;

  .matchStart {
    background: rgba(0, 0, 0, 0.65);
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .startTime {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #fff;

      span {
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.625px;
        text-shadow: 0px 0px 17px #ffffff;
      }
    }

    .btn {
      .myClass {
        opacity: 0.5;
        cursor: none;
      }
    }
  }

  .lobby {
    padding: 40px 24px 100px;

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

    .myTeam {
      .MuiPaper-root {
        background-color: transparent;

        box-shadow: 0px 0px 9px 4px #9859ff;

        .css-o4b71y-MuiAccordionSummary-content {
          margin: 0;
        }

        .MuiAccordionSummary-root {
          padding: 18px 30px;
        }

        .css-ahj2mt-MuiTypography-root {
          display: flex;
          flex-direction: column;
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.625px;
          color: #fff;
          text-shadow: 0px 0px 17px #ffffff;
          margin: 0;

          span {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.28125px;
            color: #fff;
            text-shadow: none;
            margin-bottom: 6px;
          }
        }

        .css-15v22id-MuiAccordionDetails-root {
          padding: 0;

          .teamImage {
            border-top: 1px solid #322c3f;
            border-bottom: 1px solid #322c3f;

            .image {
              padding: 16px 30px;
              text-align: center;

              img {
                width: 100%;
              }
            }
          }

          .teamInfo {
            padding: 16px 30px;

            .roomFranchize,
            .location,
            .roomName,
            .difficultyLevel,
            .solvingFrame {
              span {
                font-family: "Exo 2", sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.28125px;
                color: #a6a6a6;
              }

              h5 {
                font-family: "Exo 2", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.75px;
                margin: 8px 0 24px;
                text-transform: inherit;
              }
            }
          }

          .leaveMatch {
            padding: 24px 0;
            border-top: 1px solid #322c3f;

            button {
              background-color: transparent;
              border: none;
              font-family: "Exo 2", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              display: flex;
              align-items: center;
              text-align: center;
              text-transform: uppercase;
              text-decoration: underline;
              color: #ffffff;
              text-shadow: 0px 0px 4px #ff0202;
              margin: auto;
            }
          }
        }

        .css-yw020d-MuiAccordionSummary-expandIconWrapper {
          color: #fff;
          filter: drop-shadow(0px 0px 3px #ffffff);
        }
      }
    }

    .otherTeams {
      h2 {
        margin-top: 70px;
        margin-bottom: 24px;
      }
    }
  }
}
