.congratulations {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  padding: 20px 24px;

  position: fixed;

  .content {
    text-align: center;

    h2 {
      margin: 70px 0 16px;
    }

    p {
      margin-bottom: 32px;
    }
  }
}
