.welcomeScreen {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: fixed;

  .content {
    padding: 40px 24px;
    h2 {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 16px;
    }

    p {
      text-align: center;
    }

    a {
      text-decoration: none;
    }

    .btn {
      .button {
        margin: auto;
        margin-top: 25px;
      }
    }
  }
}
