@font-face {
  font-family: "Normatica"; /*Can be any text*/
  src: local("Normatica-Bold"),
    url("../../font/Normatica-Bold.ttf") format("truetype");
}

.winningDisplay {
  background: #000;
  height: 100vh;
  font-family: "Normatica";
  display: flex;
  flex-direction: row;
  gap: 37px;
  align-items: center;
  justify-content: center;
  padding: 0 162px;

  .winner {
    width: 50%;
    border: 2px solid #ff912c;
    position: relative;

    .dot {
      position: absolute;
      right: -8px;
      bottom: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ff912c;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
      animation: xAxis 10s linear infinite;

      @keyframes xAxis {
        0% {
          left: 0;
        }
        50% {
          left: 100%;
        }
        100% {
          left: 0;
        }
      }
    }

    .dot1 {
      position: absolute;
      left: -7px;
      bottom: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ff912c;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
      animation: YAxis 10s linear infinite;

      @keyframes YAxis {
        0% {
          top: 0;
        }
        50% {
          top: 100%;
        }
        100% {
          top: 0;
        }
      }
    }

    .dot2 {
      position: absolute;
      right: -8px;
      top: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ff912c;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
      animation: xAxis 10s linear infinite;

      @keyframes xAxis {
        0% {
          left: 0;
        }
        50% {
          left: 100%;
        }
        100% {
          left: 0;
        }
      }
    }

    .dot3 {
      position: absolute;
      right: -7px;
      bottom: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #ff912c;
      box-shadow: 0px 0px 10.3846px 4.61538px rgba(255, 145, 44, 0.7);
      animation: YAxis 10s linear infinite;

      @keyframes YAxis {
        0% {
          top: 0;
        }
        50% {
          top: 100%;
        }
        100% {
          top: 0;
        }
      }
    }

    .winnerLogo {
      text-align: center;
      padding: 65px 73px 30px;
      h5 {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0.1em;
        color: #ff912c;
        margin-top: 17px;
      }
    }

    .winnerNameId {
      display: flex;
      border-top: 2px solid #ff912c;
      border-bottom: 2px solid #ff912c;
      height: 155px;

      .winnerId {
        width: 155px;
        height: 100%;
        border-right: 2px solid #ff912c;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0.1em;
        color: #ff912c;
      }

      .winnerName {
        display: flex;
        align-items: center;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0.1em;
        color: #ffffff;
        margin-left: 52px;
      }
    }

    .winnerInfo {
      display: flex;
      img {
        height: 320px;
        width: 327px;
        padding: 25px;
        border-right: 2px solid #ff912c;
      }

      .statistics {
        margin-top: 58px;
        margin-left: 34px;
        margin-right: 56px;
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          font-size: 40px;
          line-height: 47px;
          letter-spacing: 0.1em;

          color: #f7941d;

          &:nth-child(1) {
            margin-bottom: 20px;
          }

          &:nth-child(2) {
            border-top: 2px solid #6d6e70;
            padding: 20px 0;
            border-bottom: 2px solid #6d6e70;
          }

          &:nth-child(3) {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .otherTeamsScore {
    position: relative;
    width: 100%;
    height: 756px;
    overflow-y: hidden;

    .anim {
      position: absolute;
      width: 100%;
      left: 0%;
      top: 0%;

      .topPlayerCard {
        display: flex;
        border: 2px solid #53aa65;
        height: 68px;
        margin-bottom: 26px;

        .teamRate {
          width: 69px;
          height: 100%;
          border-right: 2px solid #53aa65;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #53aa65;
        }

        .teamName {
          width: 307px;
          height: 100%;
          padding-left: 25px;
          font-size: 21px;
          line-height: 25px;
          letter-spacing: 0.1em;
          color: #ffffff;
          border-right: 2px solid #53aa65;
          display: flex;
          align-items: center;
        }

        .teamScore {
          width: 397px;
          height: 100%;
          text-align: center;
          color: #fff;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1em;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #53aa65;
          }
        }

        &:nth-child(2) {
          border: 2px solid #ed297b;
          .teamRate {
            color: #ed297b;
            border-right: 2px solid #ed297b;
          }

          .teamName {
            border-right: 2px solid #ed297b;
          }

          .teamScore {
            span {
              color: #ed297b;
            }
          }
        }

        &:nth-child(3) {
          border: 2px solid #00a2e0;
          .teamRate {
            color: #00a2e0;
            border-right: 2px solid #00a2e0;
          }

          .teamName {
            border-right: 2px solid #00a2e0;
          }

          .teamScore {
            span {
              color: #00a2e0;
            }
          }
        }

        &:nth-child(4) {
          border: 2px solid #c3986b;
          .teamRate {
            color: #c3986b;
            border-right: 2px solid #c3986b;
          }

          .teamName {
            border-right: 2px solid #c3986b;
          }

          .teamScore {
            span {
              color: #c3986b;
            }
          }
        }

        &:nth-child(5) {
          border: 2px solid #652d8f;
          .teamRate {
            color: #652d8f;
            border-right: 2px solid #652d8f;
          }

          .teamName {
            border-right: 2px solid #652d8f;
          }

          .teamScore {
            span {
              color: #652d8f;
            }
          }
        }

        &:nth-child(6) {
          border: 2px solid #fff000;
          .teamRate {
            color: #fff000;
            border-right: 2px solid #fff000;
          }

          .teamName {
            border-right: 2px solid #fff000;
          }

          .teamScore {
            span {
              color: #fff000;
            }
          }
        }

        &:nth-child(7) {
          border: 2px solid #ef5a28;
          .teamRate {
            color: #ef5a28;
            border-right: 2px solid #ef5a28;
          }

          .teamName {
            border-right: 2px solid #ef5a28;
          }

          .teamScore {
            span {
              color: #ef5a28;
            }
          }
        }

        &:nth-child(8) {
          border: 2px solid #74cf00;
          .teamRate {
            color: #74cf00;
            border-right: 2px solid #74cf00;
          }

          .teamName {
            border-right: 2px solid #74cf00;
          }

          .teamScore {
            span {
              color: #74cf00;
            }
          }
        }
      }
    }

    .anim.primary {
      animation: primary 10s linear infinite;
      width: 100%;

      @keyframes primary {
        from {
          top: 0%;
        }
        to {
          top: -100%;
        }
      }
    }
  }
}
