.team {
  background-image: url("../../images/mobileMenuBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  // height: 100vh;
  padding: 40px 24px;

  h2 {
    text-align: left;
    margin-top: 50px;
    margin-bottom: 16px;
  }

  p {
    text-align: left;
  }

  .popupBackground {
    background: rgba(25, 15, 48, 0.6);
    backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .popUp {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    bottom: 0;
    margin: auto;
    background: rgba(11, 4, 26, 0.8);
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 9px 4px #cf004b;
    transform: translate(0) !important;
    border-radius: 10px;
    margin: 0 20px;
    padding: 0 20px;
    z-index: 4;
    height: fit-content;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      margin-bottom: 45px;
    }

    .info {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.28125px;
        color: #fff;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 227px;
        margin-top: 10px;
        object-fit: cover;
        color: #fff;
      }

      .errorMessage {
        text-align: left;
        color: #cf004b;
        margin-top: 10px;
        font-size: 12px;
        margin-bottom: 0;
      }

      .popupInfo {
        margin: 0;
        text-align: left;
        text-shadow: 0px 0px 17px #ffffff;
      }
    }

    .goBack {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: transparent;
      border: none;
      color: #fff;
      text-decoration: underline;
      text-transform: uppercase;
      margin-top: 24px;
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }
  }

  form {
    margin-top: 48px;

    .element {
      display: flex;
      flex-direction: column;

      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.28125px;
        color: #fff;
        margin-left: 20px;
        margin-bottom: 10px;
      }

      input[type="text"],
      input[type="time"] {
        width: 100%;
        padding: 16px 20px;
        margin: 8px 0 30px 0;
        display: inline-block;
        background: #16131a;
        border: none;
        font-family: "Exo 2", sans-serif;
        border: 1px solid transparent;
        border-radius: 5px;
        box-sizing: border-box;
        color: #fff;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.time {
          width: 25%;

          @media only screen and (min-width: 360px) {
            width: 30%;
          }
        }

        &::placeholder-shown {
          font-family: "Exo 2", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.28125px;
          color: #535353;
        }

        &::-webkit-calendar-picker-indicator {
          display: none;
        }

        &:focus {
          background: transparent;
          border: 1px solid #ffffff;
          box-shadow: 0px 0px 10px #ffffff;
          border-radius: 5px;
        }

        &:not(:placeholder-shown) {
          // background: #16131a;
          border: 1px solid transparent;
          // border: 1px solid #ffffff;
          // box-shadow: 0px 0px 4px #ffffff;
          border-radius: 5px;
          height: 50px;

          &:focus {
            border: 1px solid #ffffff;
            box-shadow: 0px 0px 10px #ffffff;
          }
        }
      }

      input[type="time"] {
        &:not(:placeholder-shown) {
          width: fit-content;
          min-width: 80px;
        }
      }

      .errorMessage {
        color: #cf004b;
        margin-top: -15px;
        margin-bottom: 20px;
      }

      .previewImage {
        margin-bottom: 24px;
        img {
          width: 100%;
          height: 227px;
          object-fit: cover;
        }

        button {
          font-family: "Exo 2", sans-serif;
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 10px;
          text-align: center;
          text-decoration: underline;
          cursor: pointer;
          background-color: transparent;
          border: none;
          color: #fff;
        }
      }

      .btn-file {
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 1;

        .btn-file__actions__item {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 227px;
          margin-bottom: 40px;
          outline: none;
        }

        &__actions {
          &__item {
            color: #e8e8e8;
            border: 2px dashed #ffffff;
            border-radius: 5px;
            background: #16131a;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: -0.25px;
            cursor: pointer;
            text-decoration: none;

            p {
              text-align: center;
              margin-top: 14px;
              text-transform: uppercase;
              text-decoration: underline;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
            }
          }
        }
      }

      .form-group {
        label {
          &.attachment {
            width: 100%;
            .btn-create {
              & > a,
              & > div {
                margin-top: 5px;
              }
            }
            input[type="file"] {
              display: none;
            }
          }
        }

        .btn-file__actions__item--shadow {
          width: 80%;
        }
      }
    }
  }
  .teamSpiner {
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #16131aa3;
  }
}
