@font-face {
  font-family: "Normatica"; /*Can be any text*/
  src: local("Normatica-Bold"),
    url("../../font/Normatica-Bold.ttf") format("truetype");
}

.inGame {
  background: #000;
  height: 100vh;
  overflow-y: scroll;
  font-family: "Normatica";

  .inGameContent {
    padding: 82px 95px;

    .topBoard {
      ul {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #6e6f71;
        border-top: 2px solid #6d6e70;
        margin-bottom: 67px;

        li {
          // padding: 22px 0;
          height: 47px;
          border-right: 2px solid #6d6e70;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(1) {
            width: 262px;
          }

          &:nth-child(2) {
            width: 305px;
          }

          &:nth-child(3) {
            width: 257px;
          }

          &:nth-child(4) {
            width: 186px;
          }

          &:nth-child(5) {
            width: 325px;
          }

          &:nth-child(6) {
            width: 201px;
          }

          &:last-child {
            width: 192px;
            border-right: none;
          }
        }
      }
    }

    .teamsInGame {
      .specificTeam {
        width: 100%;
        height: 150px;
        margin-bottom: 67px;
        border: 2px solid #ed297b;
        display: flex;

        .starginPlayer {
          width: 220px;
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          border-right: 2px solid #ed297b;

          span {
            width: 50%;
            height: 100%;
            text-align: center;
            background: #ed297b;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 64px;
            line-height: 70px;
            color: #ffffff;
          }

          img {
            width: 50%;
            height: 100%;
            object-fit: cover;
          }
        }

        .wraperProgress {
          display: flex;
          position: relative;

          .teamName {
            width: 306px;
            border-right: 2px solid #ed297b;
            text-align: center;
            padding-top: 39px;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.1em;
            color: #ffffff;
          }

          .companyAndRoom {
            width: 250px;
            text-align: center;
            padding-top: 39px;
            border-right: 2px solid #ed297b;

            h5 {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.1em;
              color: #ffffff;
              margin-bottom: 4px;
            }

            h6 {
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.1em;
              color: #ed297b;
            }
          }

          .teamCity {
            width: 187px;
            text-align: center;
            padding-top: 39px;
            border-right: 2px solid #ed297b;

            h6 {
              font-size: 16px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.1em;
              color: #ffffff;
              width: 60%;
              margin: auto;
            }
          }

          .givenHints {
            width: 325px;
            padding-top: 39px;
            border-right: 2px solid #ed297b;
            text-align: center;
            display: flex;
            justify-content: center;
            gap: 15px;
            position: relative;

            .aditionalHints {
              position: absolute;
              color: #fff;
              right: 15px;
              top: 25px;
              font-size: 12px;
            }

            .shake {
              animation: blink linear 1s;
            }

            @keyframes blink {
              78% {
                opacity: 0.2;
              }
              79% {
                opacity: 0.3;
              }
              80% {
                opacity: 1;
              }
              81% {
                opacity: 0.3;
              }
              82% {
                opacity: 0.5;
              }
              83% {
                opacity: 0.2;
              }
              92% {
                opacity: 0.5;
              }
              94% {
                opacity: 0.2;
              }
              95% {
                opacity: 0.5;
              }
              100% {
                opacity: 1;
              }
            }
          }

          .timeLeft {
            width: 201px;
            border-right: 2px solid #ed297b;
            padding-top: 20px;

            .time {
              margin: auto;
              margin-left: 16px;
              margin-right: 16px;
              height: 45px;
              background: #ed297b;
              font-size: 48px;
              line-height: 50px;
              color: #ffffff;
              padding: 16px;
              text-align: center;
            }
          }

          .points {
            padding-top: 36px;
            width: 191px;
            text-align: center;
            font-size: 48px;
            line-height: 50px;
            color: #ed297b;
          }

          .progresses {
            position: absolute;
            left: 22px;
            padding-top: 12px;
            padding-bottom: 15px;
            bottom: 0;
            background: #000;
            width: 97%;

            progress {
              width: 100%;
              height: 14px;
              background: #ed297b;

              &::-webkit-progress-value {
                background-color: #ed297b;

                // Bounce
                transition: all 2s cubic-bezier(0.47, 1.64, 0.41, 0.8);
              }
            }
          }
        }

        &:nth-child(2) {
          border: 2px solid #00a2e0;

          .starginPlayer {
            border-right: 2px solid #00a2e0;

            span {
              background: #00a2e0;
            }
          }

          .teamName {
            border-right: 2px solid #00a2e0;
          }

          .companyAndRoom {
            border-right: 2px solid #00a2e0;
            h6 {
              color: #00a2e0;
            }
          }

          .teamCity {
            border-right: 2px solid #00a2e0;
          }

          .givenHints {
            border-right: 2px solid #00a2e0;

            .inactive {
              svg {
                path {
                  stroke: #00a2e0;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #00a2e0;
                  fill: #00a2e0;
                }
              }
            }
          }

          .timeLeft {
            border-right: 2px solid #00a2e0;

            .time {
              background: #00a2e0;
            }
          }

          .points {
            color: #00a2e0;
          }

          .progresses {
            progress {
              background: #00a2e0;

              &::-webkit-progress-value {
                background: #00a2e0;
              }
            }
          }
        }

        &:nth-child(3) {
          border: 2px solid #ff912c;

          .starginPlayer {
            border-right: 2px solid #ff912c;

            span {
              background: #ff912c;
            }
          }

          .teamName {
            border-right: 2px solid #ff912c;
          }

          .companyAndRoom {
            border-right: 2px solid #ff912c;
            h6 {
              color: #ff912c;
            }
          }

          .teamCity {
            border-right: 2px solid #ff912c;
          }

          .givenHints {
            border-right: 2px solid #ff912c;

            .inactive {
              svg {
                path {
                  stroke: #ff912c;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #ff912c;
                  fill: #ff912c;
                }
              }
            }
          }

          .timeLeft {
            border-right: 2px solid #ff912c;

            .time {
              background: #ff912c;
            }
          }

          .points {
            color: #ff912c;
          }

          .progresses {
            progress {
              background: #ff912c;

              &::-webkit-progress-value {
                background: #ff912c;
              }
            }
          }
        }

        &:nth-child(4) {
          border: 2px solid #53aa65;

          .starginPlayer {
            border-right: 2px solid #53aa65;

            span {
              background: #53aa65;
            }
          }

          .teamName {
            border-right: 2px solid #53aa65;
          }

          .companyAndRoom {
            border-right: 2px solid #53aa65;
            h6 {
              color: #53aa65;
            }
          }

          .teamCity {
            border-right: 2px solid #53aa65;
          }

          .givenHints {
            border-right: 2px solid #53aa65;

            .inactive {
              svg {
                path {
                  stroke: #53aa65;
                }
              }
            }

            .active {
              svg {
                .change {
                  stroke: #53aa65;
                  fill: #53aa65;
                }
              }
            }
          }

          .timeLeft {
            border-right: 2px solid #53aa65;

            .time {
              background: #53aa65;
            }
          }

          .points {
            color: #53aa65;
          }

          .progresses {
            progress {
              background: #53aa65;

              &::-webkit-progress-value {
                background: #53aa65;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .topPlayers {
      margin: 138px 76px 140px;
      display: flex;
      flex-direction: row;
      gap: 29px;

      .leftSide {
        width: 50%;

        .topPlayerCard {
          display: flex;
          border: 2px solid #6d6e70;
          height: 68px;
          margin-bottom: 26px;

          .teamRate {
            width: 69px;
            height: 100%;
            border-right: 2px solid #6d6e70;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 0.1em;
            color: #ff912c;
          }

          .teamName {
            width: 307px;
            height: 100%;
            padding-left: 25px;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 0.1em;
            color: #ffffff;
            border-right: 2px solid #6d6e70;
            display: flex;
            align-items: center;
          }

          .teamScore {
            width: 397px;
            height: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.1em;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: #ff912c;
            }
          }

          &:nth-child(2) {
            .teamRate {
              color: #53aa65;
            }

            .teamScore {
              span {
                color: #53aa65;
              }
            }
          }

          &:nth-child(3) {
            .teamRate {
              color: #ed297b;
            }

            .teamScore {
              span {
                color: #ed297b;
              }
            }
          }

          &:nth-child(4) {
            .teamRate {
              color: #00a2e0;
            }

            .teamScore {
              span {
                color: #00a2e0;
              }
            }
          }

          &:nth-child(5) {
            .teamRate {
              color: #c3986b;
            }

            .teamScore {
              span {
                color: #c3986b;
              }
            }
          }
        }
      }

      .rightSide {
        width: 50%;

        .topPlayerCard {
          display: flex;
          border: 2px solid #6d6e70;
          height: 68px;
          margin-bottom: 26px;

          .teamRate {
            width: 69px;
            height: 100%;
            border-right: 2px solid #6d6e70;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 0.1em;
            color: #652d8f;
          }

          .teamName {
            width: 307px;
            height: 100%;
            padding-left: 25px;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 0.1em;
            color: #ffffff;
            border-right: 2px solid #6d6e70;
            display: flex;
            align-items: center;
          }

          .teamScore {
            width: 397px;
            height: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.1em;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              color: #652d8f;
            }
          }

          &:nth-child(2) {
            .teamRate {
              color: #fff000;
            }

            .teamScore {
              span {
                color: #fff000;
              }
            }
          }

          &:nth-child(3) {
            .teamRate {
              color: #ef5a28;
            }

            .teamScore {
              span {
                color: #ef5a28;
              }
            }
          }

          &:nth-child(4) {
            .teamRate {
              color: #74cf00;
            }

            .teamScore {
              span {
                color: #74cf00;
              }
            }
          }

          &:nth-child(5) {
            .teamRate {
              color: #8a5d3c;
            }

            .teamScore {
              span {
                color: #8a5d3c;
              }
            }
          }
        }
      }
    }
  }
}
